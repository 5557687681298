import contract from './assets/contract.png';
import fun from './assets/fun.png';
import project from './assets/project.png';
import work from './assets/work.png';

export const icons: any = {
  contract,
  fun,
  project,
  work,
};
